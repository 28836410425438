@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  // --primary: #cff116;
  --primary: #3c9c81;
  // --black: #130e17;
  // --white: #fdfdfd;
  --black: #000000;
  --white: #ffffff;
  --blink-rate: 1.5s;
}

$link-color: #3c9c81;

// Override default variables before the import
$theme-colors: (
  'primary': #3c9c81,
  'primary-dark': #163a30,
  'secondary': #323969,
  'danger': #ff4136,
  'orange': #ffa136,
  // 'black': #130e17,
  'black': #000000,
  'grey': #9d9d9d,
  // 'white': #bcb7b5,
  'white': #ffffff,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://use.typekit.net/oqv4gmc.css');
@import url('https://use.typekit.net/ygz0muq.css');

body {
  background-color: var(--black) !important;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  margin: 0 !important;
}

ul {
  list-style-type: none;
}

.text-lg-h1 {
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h2 {
  font-size: 46px;
  font-weight: 400;
  line-height: 1.3;
}

.text-lg-h3 {
  font-size: 33px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h5 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.text-lg-p1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}

.text-lg-p2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.text-lg-b1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-b2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}
.text-lg-b3 {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.bb {
  border: 1px solid var(--black);
}

.bb-bottom {
  border-bottom: 1px solid var(--black);
}

.bb-top {
  border-top: 1px solid var(--black);
}

.bb-left {
  border-left: 1px solid var(--black);
}

.bb-right {
  border-right: 1px solid var(--black);
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-35 {
  height: 35%;
}

.h-40 {
  height: 40%;
}

.h-45 {
  height: 45%;
}

.h-50 {
  height: 50%;
}

.h-55 {
  height: 55%;
}

.h-60 {
  height: 60%;
}

.h-65 {
  height: 65%;
}
.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}
.h-80 {
  height: 80%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-underline:hover,
.no-underline:focus {
  text-decoration: none;
  box-shadow: none;
}

.no-focus:focus {
  box-shadow: none;
  border-bottom: 1px solid var(--black) !important;
}

.bt-0 {
  border-top: none !important;
}

.br-0 {
  border-right: none !important;
}

.bl-0 {
  border-left: none !important;
}

.adz {
  background-color: white;
  border: none;
  border-bottom: 1px solid var(--black) !important;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem 0.75rem;
}

#inputID::placeholder {
  color: #000;
  opacity: 1;
}

.mw-550 {
  max-width: 550px !important;
}

.primary-hover:hover {
  background-color: var(--primary) !important;
  color: var(--black) !important;
  border: none !important;
}

.link-btn {
  height: 50px;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-link {
  background-color: var(--primary);
  color: #000;
  display: inline-block;
  padding: 2px 6px;

  &:hover {
    background-color: var(--black);
    color: var(--primary);
    cursor: pointer !important;
    text-decoration: underline;
  }
}

.blink-me {
  -webkit-animation: blink var(--blink-rate) linear infinite;
  -moz-animation: blink var(--blink-rate) linear infinite;
  -ms-animation: blink var(--blink-rate) linear infinite;
  -o-animation: blink var(--blink-rate) linear infinite;
  animation: blink var(--blink-rate) linear infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

// media queries
// for setting styles based on min width
@mixin mq($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (min-width: $value) {
    @content;
  }
}

// for setting styles based on max width.
@mixin mq-m($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (max-width: $value - 1) {
    @content;
  }
}

.hover-black:hover {
  color: var(--black) !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btn-primary:focus {
  box-shadow: none;
}

body::-webkit-scrollbar,
.no-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent; /* Change the color to match the background */
}

body::-webkit-scrollbar-thumb,
.no-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent; /* Change the color to match the background */
}

body::-webkit-scrollbar-thumb:hover,
.no-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent; /* Change the color to match the background */
}
