.account-pill {
  height: 35px;
  margin-right: -20px;
  padding-right: 26px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.connect-pill {
  background-color: var(--black) !important;
  height: 45px;
}

.connect-pill:hover,
.account-pill:hover {
  background-color: var(--primary) !important;
  color: var(--black) !important;
  border: 1px solid var(--black) !important;
}

.account-pill,
.connect-pill {
  border: 1px solid var(--white) !important;
}
